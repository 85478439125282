import React, { ReactNode } from 'react';

import { Flex, FlexProps, Text } from '@chakra-ui/react';

type CommentBubbleProps = {
  message: ReactNode;
  personRole: string;
  name?: string;
  isRevealed: boolean;
  animationDuration?: string;
  animationDelay?: string;
} & FlexProps;

export const CommentBubble = ({
  message,
  personRole,
  name,
  isRevealed,
  animationDuration,
  animationDelay = '',
  ...chakraProps
}: CommentBubbleProps) => {
  return (
    <Flex
      flexDirection="column"
      className="revealedMemberText"
      p={6}
      position={{ base: 'relative', sm: 'absolute' }}
      top={{ sm: '0' }}
      left={{ sm: 'calc(100% + 23px)' }}
      borderRadius="16px"
      backgroundColor="lime.500"
      color="black"
      transform={`translateY(${isRevealed ? '0px' : '30px'})`}
      opacity={`${isRevealed ? 1 : 0}`}
      transition={`transform ${animationDuration} ease ${animationDelay}, opacity ${animationDelay}`}
      {...chakraProps}
    >
      <Text
        variant={{
          base: 'text-xs/lineHeight-4/font-medium/0.75rem',
          sm: 'text-sm/lineHeight-5/font-medium',
        }}
        color="black"
        flex={1}
      >
        {message}
      </Text>
      {name && (
        <Text
          pt={2}
          variant={{
            base: 'text-xs/lineHeight-4/font-medium/0.75rem',
            sm: 'text-sm/lineHeight-5/font-normal',
          }}
          color="black"
        >
          {name}
        </Text>
      )}
      <Text variant="text-xs/lineHeight-4/font-normal/0.75rem" color="blackAlpha.600">
        {personRole}
      </Text>
    </Flex>
  );
};

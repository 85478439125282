import React, { ReactNode } from 'react';

import { Box, BoxProps, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import Star from 'images/doodles/star.png';
import Image from 'next/image';

import {
  AvatarSize,
  QuotationAuthor,
  QuotationAuthorProps,
} from '@/components/functional/QuotationAuthor/QuotationAuthor';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

export type QuotationSectionProps = {
  text: ReactNode;
  author: QuotationAuthorProps;
} & BoxProps;

export const QuotationSection = ({ text, author, ...style }: QuotationSectionProps) => {
  const avatarSize = useBreakpointValue({
    base: 'md',
    md: 'lg',
  });

  return (
    <SectionWrapper>
      <SectionContentWrapper
        paddingX={{ base: 5, sm: 6, lg: 28, '3xl': 80 }}
        paddingY={{ base: 16, sm: 28 }}
        {...style}
      >
        <Flex flexDir="column" alignItems="center">
          <Heading
            position="relative"
            as="h2"
            variant={{
              base: 'component-heading-large/lg',
              sm: 'component-heading-large/xl',
              md: 'component-heading-large/2xl',
            }}
            color="purple.500"
            textAlign="center"
            maxW={{
              base: '100%',
              xs: '90%',
              sm: '80%',
              md: '70%',
              lg: '1040px',
            }}
            mb={{
              base: 9,
              md: 16,
            }}
          >
            <AnimateWhenInViewWrapper>{text}</AnimateWhenInViewWrapper>
            <Box
              as={Image}
              src={Star}
              alt=""
              width="auto"
              height={{
                base: '40px',
                sm: '94px',
                md: '135px',
              }}
              zIndex={0}
              position="absolute"
              left={{
                base: '',
                sm: -20,
              }}
              bottom={{
                base: -10,
                sm: -24,
              }}
            />
            <Box
              as={Image}
              src={Star}
              alt=""
              width="auto"
              height={{
                base: '32px',
                sm: '64px',
                md: '92px',
              }}
              zIndex={0}
              position="absolute"
              right={{
                base: -2,
                sm: -20,
              }}
              top={{
                base: -6,
                sm: -8,
              }}
            />
          </Heading>
          <AnimateWhenInViewWrapper>
            <QuotationAuthor
              role={author.role}
              company={author.company}
              avatar={author.avatar || ''}
              name={author.name}
              size={(avatarSize as AvatarSize) || 'lg'}
              fontVariant={{
                base: 'text-xs/lineHeight-4/font-book/0.75rem',
                md: 'text-md/lineHeight-6/font-normal',
              }}
            />
          </AnimateWhenInViewWrapper>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

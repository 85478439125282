import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

type PlusMinusIconAnimatedProps = {
  plus: boolean;
  chakraProps?: BoxProps;
};

export const PlusMinusIconAnimated = ({ plus, chakraProps }: PlusMinusIconAnimatedProps) => {
  return (
    <Box w="14px" h="2px" bg="gray.700" position="relative">
      <Box
        w="14px"
        h="2px"
        bg="gray.700"
        position="absolute"
        transform={plus ? 'rotate(90deg)' : 'rotate(0deg)'}
        transition="transform .3s"
        {...chakraProps}
      />
    </Box>
  );
};

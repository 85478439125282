import React, { useEffect, useRef } from 'react';

import { Box, ChakraStyledOptions, Flex, FlexProps, Link } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { StaticImageData } from 'next/image';
import Image from 'next/image';
import LatestProjectsBadge from 'services/mvp/latest-mvp-projects-badge.png';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { getPercentageInRange } from '@/utils/helper-functions/percentageInRange';

export type ScrollSliderItem = {
  slideImage: StaticImageData | string;
  imageAlt?: string;
  url?: string;
};

export type ScrollSliderProps = {
  items: ScrollSliderItem[];
  badgeText?: string;
  badgeImage?: StaticImageData;
  style?: FlexProps;
  imageWrapperStyle?: ChakraStyledOptions;
  fadedBackground?: boolean;
};

const SCROLL_TRANSFORM_SIZE = 200; // the bigger, the more translate the container will get
const MIN_BADGE_TILT_DEG = -20;
const MAX_BADGE_TILT_DEG = 40;

export const ScrollSlider = ({
  items,
  badgeText,
  badgeImage,
  style,
  imageWrapperStyle,
  fadedBackground,
}: ScrollSliderProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const badgeRef = useRef<HTMLDivElement | null>(null);
  const scrolledContainerRef = useRef<HTMLDivElement | null>(null);
  const debouncedOnScroll = useRef(() => debounce(() => onScroll(), 10));

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', debouncedOnScroll.current());
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      window.removeEventListener('scroll', debouncedOnScroll.current());
    };
  }, []);

  const onScroll = () => {
    if (window && containerRef.current) {
      const containerDistanceFromTop = containerRef.current.getBoundingClientRect().top || 0;
      const vh100 = window.innerHeight;
      const containerHeight = containerRef.current.offsetHeight;

      if (
        scrolledContainerRef.current &&
        containerDistanceFromTop - vh100 < 0 &&
        containerDistanceFromTop > containerHeight * -1
      ) {
        const scrollPercentage = +((containerDistanceFromTop + containerHeight) / (vh100 + containerHeight)).toFixed(2);
        scrolledContainerRef.current.style.transform = `translateX(${
          (1 - scrollPercentage) * -SCROLL_TRANSFORM_SIZE
        }px)`;
        if (badgeRef.current) {
          const tilt = getPercentageInRange(1 - scrollPercentage, MIN_BADGE_TILT_DEG, MAX_BADGE_TILT_DEG);
          badgeRef.current.style.transform = `rotate(${Math.ceil(tilt)}deg)`;
        }
      }
    }
  };

  return (
    <SectionWrapper>
      <SectionContentWrapper ref={containerRef} paddingX={undefined} maxWidth="unset">
        {fadedBackground && (
          <Box
            background="linear-gradient(0deg, rgba(229,220,244,1) 0%, rgba(229,220,244,0.4) 70%, transparent 100%)"
            width="100%"
            height="150px"
            position="absolute"
            bottom={0}
            left={0}
          />
        )}
        {badgeText && (
          <Box
            ref={badgeRef}
            position="absolute"
            width={{
              base: '78px',
              sm: '112px',
              md: '200px',
            }}
            zIndex={2}
            top={{
              base: '7%',
              md: '4%',
            }}
            left={{
              base: '5%',
              sm: '20%',
              md: '5%',
              lg: '8%',
            }}
            transition="transform .5s linear"
          >
            <Box as={Image} src={badgeImage || LatestProjectsBadge} alt={badgeText} />
          </Box>
        )}
        <Flex
          gap={{
            base: 4,
            lg: 8,
          }}
          ref={scrolledContainerRef}
          transition="transform .3s ease-out"
          sx={style}
        >
          {items.map((item, index) => (
            <Flex
              w={{
                base: '227px',
                md: '402px',
              }}
              h={{
                base: '227px',
                md: '402px',
              }}
              {...(item.url ? { as: Link, target: '_blank', href: item.url } : null)}
              cursor={item.url ? 'pointer' : 'initial'}
              flexShrink={0}
              key={`scroll-item-${
                typeof item.slideImage === 'string' ? item.slideImage : item.slideImage.src
              }-${index}`}
              transition="transform .2s"
              _hover={{
                transform: 'scale(1.12)',
              }}
              borderRadius="32px"
              overflow="hidden"
              sx={imageWrapperStyle}
            >
              <Image
                src={item.slideImage}
                alt={item.imageAlt || ''}
                width={400}
                height={400}
                style={{
                  objectFit: 'cover',
                }}
              />
            </Flex>
          ))}
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

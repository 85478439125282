import { Flex, FlexProps } from '@chakra-ui/react';
import ArrowLeft from 'images/icons/client-opinions/arrow-left.svg';
import ArrowRight from 'images/icons/client-opinions/arrow-right.svg';

import { Button } from '@/components/atoms';

export interface CardNavigationProps extends FlexProps {
  onSlidePrev: () => void;
  onSlideNext: () => void;
  variant?: 'dark' | 'light';
  noUnderline?: boolean;
}

export const SliderControls = (props: CardNavigationProps) => {
  const { onSlidePrev, onSlideNext, variant, ...chakraProps } = props;
  const isDarkVariant = variant === 'dark';

  return (
    <Flex justifyContent="flex-end" alignItems="end" pb={4} {...chakraProps}>
      <Button
        label=""
        aria-label="previous slide"
        leftIcon={<ArrowLeft />}
        iconSpacing={0}
        onClick={onSlidePrev}
        variant={isDarkVariant ? 'sliderDark' : 'sliderLight'}
      />
      <Button
        label=""
        aria-label="next slide"
        ml={4}
        rightIcon={<ArrowRight />}
        iconSpacing={0}
        onClick={onSlideNext}
        variant={isDarkVariant ? 'sliderDark' : 'sliderLight'}
      />
    </Flex>
  );
};

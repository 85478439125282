import { ElementType } from 'react';

import BrushedHighlightLime from 'images//highlights/creme-de-la-creme-highlight.png';
import Crown from 'images/doodles/crown.svg';
import LeftSunlight from 'images/doodles/exclamation-doodle-lines-2.svg';
import ExclamationLines from 'images/doodles/exclamation-doodle-lines.svg';
import Circle1 from 'images/highlights/circle-1.svg';
import Circle2 from 'images/highlights/circle-2.svg';
import Circle3 from 'images/highlights/circle-3.svg';
import Circle4 from 'images/highlights/circle-4.svg';
import Circle5 from 'images/highlights/circle-5.svg';
import SlightlyRaggedHighlightLime from 'images/highlights/client-opinions-highlight.png';
import UnderLineOne from 'images/highlights/line-1-straight.svg';
import UnderLineThreeBent from 'images/highlights/line-3-cross-2.svg';
import UnderLineThree from 'images/highlights/line-3-cross.svg';
import UnderLineTwo from 'images/highlights/line-8.svg';
import SlightlyRaggedHighlight from 'images/highlights/violet-text-highlight.png';
import { omitBy } from 'lodash';
import { StaticImageData } from 'next/image';

import {
  ComponentCommonComponentsDecoratedTextImage,
  Enum_Componentcommoncomponentsdecoratedtextimage_Circle,
  Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim,
  Enum_Componentcommoncomponentsdecoratedtextimage_Highlight,
  Enum_Componentcommoncomponentsdecoratedtextimage_Underline,
} from '@/graphql/generated';

type Size = {
  base: number | string;
  sm?: number | string;
  md?: number | string;
};

type IconProps<T> = {
  icon: T;
  props: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    transform?: string;
    width?: Size;
    height?: Size;
    scaleX?: number;
  };
};

type CircleIcon = Record<Enum_Componentcommoncomponentsdecoratedtextimage_Circle, IconProps<ElementType>>;
type ExclaimIcon = Record<Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim, IconProps<ElementType>>;
type HighlightIcon = Record<Enum_Componentcommoncomponentsdecoratedtextimage_Highlight, IconProps<StaticImageData>>;
type UnderlineIcon = Record<Enum_Componentcommoncomponentsdecoratedtextimage_Underline, IconProps<ElementType>>;

export const exclaimIcon: ExclaimIcon = {
  [Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim.LeftCrown]: {
    icon: Crown,
    props: {
      top: 0,
      left: 0,
      transform: 'translate(-40%, -60%)',
      width: {
        base: '36px',
        sm: '48px',
      },
      height: {
        base: '100%',
      },
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim.LeftSunlight]: {
    icon: LeftSunlight,
    props: {
      top: 0,
      transform: 'translate(-100%, -60%)',
      width: {
        base: '30px',
        md: '40px',
      },
      height: {
        base: '100%',
      },
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim.RightSunlight]: {
    icon: ExclamationLines,
    props: {
      top: 0,
      left: 'unset',
      right: 0,
      transform: 'translate(75%, -5%) rotate(90deg)',
      width: {
        base: '28px',
        sm: '34px',
        md: '45px',
      },
      height: {
        base: '28px',
        sm: '34px',
        md: '45px',
      },
    },
  },
};

export const underlineIcon: UnderlineIcon = {
  [Enum_Componentcommoncomponentsdecoratedtextimage_Underline.OneLine]: {
    icon: UnderLineOne,
    props: {
      transform: 'translateY(60%)',
      top: 0,
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Underline.TwoLines]: {
    icon: UnderLineTwo,
    props: {
      transform: 'translateY(80%)',
      top: 0,
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Underline.ThreeLinesCrossed]: {
    icon: UnderLineThree,
    props: {
      transform: 'translateY(60%)',
      top: 0,
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Underline.ThreeLinesCrossedBent]: {
    icon: UnderLineThreeBent,
    props: {
      right: 0,
      transform: 'translateY(60%)',
      top: 0,
    },
  },
};

export const circleIcon: CircleIcon = {
  [Enum_Componentcommoncomponentsdecoratedtextimage_Circle.Circle_1]: {
    icon: Circle1,
    props: {
      top: '-5px',
      left: '0',
      // transform: 'scaleX(1.6) scaleY(1.4)',
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Circle.Circle_2]: {
    icon: Circle2,
    props: {},
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Circle.Circle_3]: {
    icon: Circle3,
    props: {},
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Circle.Circle_4]: {
    icon: Circle4,
    props: {},
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Circle.Circle_5]: {
    icon: Circle5,
    props: {},
  },
};

export const highlightIcon: HighlightIcon = {
  [Enum_Componentcommoncomponentsdecoratedtextimage_Highlight.SlightlyRaggedLime]: {
    icon: SlightlyRaggedHighlightLime,
    props: {
      scaleX: 1.05,
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Highlight.SlightlyRaggedViolet]: {
    icon: SlightlyRaggedHighlight,
    props: {
      scaleX: 1.05,
    },
  },
  [Enum_Componentcommoncomponentsdecoratedtextimage_Highlight.BrushedLime]: {
    icon: BrushedHighlightLime,
    props: {
      scaleX: 1.1,
    },
  },
};

export type TextDecorationIcons = {
  highlight?: (typeof Enum_Componentcommoncomponentsdecoratedtextimage_Highlight)[keyof typeof Enum_Componentcommoncomponentsdecoratedtextimage_Highlight];
  underline?: (typeof Enum_Componentcommoncomponentsdecoratedtextimage_Underline)[keyof typeof Enum_Componentcommoncomponentsdecoratedtextimage_Underline];
  exclaim?: (typeof Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim)[keyof typeof Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim];
  circle?: (typeof Enum_Componentcommoncomponentsdecoratedtextimage_Circle)[keyof typeof Enum_Componentcommoncomponentsdecoratedtextimage_Circle];
};

export const parseTextDecoration = (
  text: ComponentCommonComponentsDecoratedTextImage | null | undefined,
): TextDecorationIcons => {
  const { circle, exclaim, highlight, underline } = text ?? {};

  return omitBy({ circle, highlight, exclaim, underline }, (image) => !image);
};

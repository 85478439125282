import React from 'react';

import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import BackgroundDoodle from 'services/grid-background-doodle.png';

export const MShapedBackground = () => {
  return (
    <Box
      as={Image}
      src={BackgroundDoodle}
      alt=""
      position="absolute"
      zIndex={-1}
      h="565px"
      maxW="unset"
      w="auto"
      transform="translateY(-50%)"
      top={{
        base: '50%',
      }}
      left={{
        base: '-300%',
        xs: '-150%',
        sm: '-130%',
        md: '-70%',
        lg: '-50%',
        '2xl': '-30%',
      }}
      opacity={0.3}
    />
  );
};

import React, { useState } from 'react';

import { Box, ChakraStyledOptions, Flex, Text, useMediaQuery, useQuery } from '@chakra-ui/react';
import Image from 'next/image';
import SeeHiddenDesktop from 'services/mvp/see-whats-hidden-desktop.png';
import SeeHiddenTablet from 'services/mvp/see-whats-hidden-tablet.png';

import { CommentBubble } from '@/components/atoms/CommentBubble/CommentBubble';
import { FacesSectionItem as FacesSectionItemType } from '@/components/sections/services/typings';

type FacesSectionItemProps = {
  isLast: boolean;
  isMobile?: boolean;
  showExtraBubbles?: boolean;
  style?: ChakraStyledOptions;
} & FacesSectionItemType;

export const FacesSectionItem = ({
  image1,
  image2,
  name,
  comment,
  position,
  isLast,
  isMobile,
  showExtraBubbles = true,
  style,
}: FacesSectionItemProps) => {
  // TODO: replace
  const [isAboveSM, isAboveLG] = useMediaQuery([useQuery({ above: 'sm' }), useQuery({ above: 'lg' })]);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      position="relative"
      onMouseEnter={() => (isMobile ? null : setIsHovered(true))}
      onMouseLeave={() => (isMobile ? null : setIsHovered(false))}
      onClick={() => setIsHovered(!isHovered)}
      sx={style}
    >
      <Box
        as={Image}
        src={image1}
        alt={name}
        height="170px"
        maxW="unset"
        w="auto"
        transform={`scale(${isHovered ? 0 : 1})`}
        transition="transform .2s"
        mb={6}
        className="initial-image"
      />
      <Box
        as={Image}
        src={image2}
        alt={name}
        left="50%"
        top="0"
        transform={`translateX(-50%) scale(${isHovered ? 1 : 0})`}
        transition="transform .2s"
        position="absolute"
        height="180px"
        maxW="unset"
        w="auto"
        borderRadius="100%"
        className="hover-image"
      />
      <Text color="purple.500" variant="text-md/lineHeight-6/font-semibold" mb={1}>
        {name}
      </Text>
      <Text color="purple.400" variant="text-md/lineHeight-6/font-normal">
        {position}
      </Text>

      <CommentBubble
        personRole={position}
        message={comment}
        name={name}
        isRevealed={isHovered}
        animationDuration=".3s"
        zIndex={99}
        top={0}
        left="50%"
        transform="translate(-50%, -120%)"
        transformOrigin="left bottom"
        position="absolute"
        pointerEvents="none"
        minWidth="250px"
      />

      {showExtraBubbles && (
        <>
          {isLast && isAboveSM && !isAboveLG && (
            <Box
              w="146px"
              as={Image}
              src={SeeHiddenTablet}
              alt="see whats hidden"
              position="absolute"
              bottom="-64%"
              right="-16%"
            />
          )}

          {isLast && isAboveLG && (
            <Box
              w="146px"
              as={Image}
              src={SeeHiddenDesktop}
              alt="see whats hidden"
              position="absolute"
              top="-64%"
              right="0"
            />
          )}
        </>
      )}
    </Flex>
  );
};

import AWSIcon from 'icons/technologies/aws.png';
import AzureIcon from 'icons/technologies/azure.png';
import DockerIcon from 'icons/technologies/docker.png';
import ExpressIcon from 'icons/technologies/express.png';
import GCPIcon from 'icons/technologies/gcp.png';
import GitHubIcon from 'icons/technologies/git.png';
import GraphQLIcon from 'icons/technologies/graphql.png';
import JavaIcon from 'icons/technologies/java.png';
import JSIcon from 'icons/technologies/javascript.png';
import KubernetesIcon from 'icons/technologies/kubernetes.png';
import MongoIcon from 'icons/technologies/mongo.png';
import NeoIcon from 'icons/technologies/neo.png';
import NestIcon from 'icons/technologies/nest.png';
import NodeJSIcon from 'icons/technologies/node.png';
import PostgresIcon from 'icons/technologies/postgres.png';
import ReactIcon from 'icons/technologies/react.png';
import RestIcon from 'icons/technologies/rest.png';
import RubyIcon from 'icons/technologies/ruby.png';
import SpringIcon from 'icons/technologies/spring.png';
import TSIcon from 'icons/technologies/typescript.png';

import { StrapiMediaAttributes } from '@/typings/strapi';

export type TechnologyItem = {
  header: string;
  items: TechnologyItemImage[];
  gridColumn?: string;
};

export type TechnologyItemImage = {
  name: string;
  icon: { caption?: string | null } & StrapiMediaAttributes;
  gridRow?: string;
};

export const technologyItems: TechnologyItem[] = [
  {
    header: 'frontend',
    items: [
      {
        name: 'React',
        icon: {
          url: ReactIcon.src,
        },
      },
      {
        name: 'JavaScript',
        icon: {
          url: JSIcon.src,
        },
      },
    ],
  },
  {
    header: 'backend',
    items: [
      {
        name: 'TypeScript',
        icon: {
          url: TSIcon.src,
        },
        gridRow: '1',
      },
      {
        name: 'Java',
        icon: {
          url: JavaIcon.src,
        },
        gridRow: '1',
      },
      {
        name: 'Spring',
        icon: {
          url: SpringIcon.src,
        },
        gridRow: '1',
      },
      {
        name: 'Ruby on Rails',
        icon: {
          url: RubyIcon.src,
        },
        gridRow: '2',
      },
      {
        name: 'Node.js',
        icon: {
          url: NodeJSIcon.src,
        },
        gridRow: '2',
      },
      {
        name: 'Rest',
        icon: {
          url: RestIcon.src,
        },
        gridRow: '2',
      },
      {
        name: 'NestJS',
        icon: {
          url: NestIcon.src,
        },
        gridRow: '3',
      },
      {
        name: 'Express',
        icon: {
          url: ExpressIcon.src,
        },
        gridRow: '3',
      },
      {
        name: 'GraphQL',
        icon: {
          url: GraphQLIcon.src,
        },
        gridRow: '3',
      },
    ],
    gridColumn: '2 / span 3',
  },
  {
    header: 'mobile',
    items: [
      {
        name: 'React Native',
        icon: {
          url: ReactIcon.src,
        },
      },
    ],
  },
  {
    header: 'infrastructure',
    items: [
      {
        name: 'AWS',
        icon: {
          url: AWSIcon.src,
        },
      },
      {
        name: 'Azure',
        icon: {
          url: AzureIcon.src,
        },
      },
      {
        name: 'Google Cloud',
        icon: {
          url: GCPIcon.src,
        },
      },
    ],
  },
  {
    header: 'devops',
    items: [
      {
        name: 'Kubernetes',
        icon: {
          url: KubernetesIcon.src,
        },
      },
      {
        name: 'Docker',
        icon: {
          url: DockerIcon.src,
        },
      },
      {
        name: 'GitHub',
        icon: {
          url: GitHubIcon.src,
        },
      },
    ],
  },
  {
    header: 'databases',
    items: [
      {
        name: 'PostgreSQL',
        icon: {
          url: PostgresIcon.src,
        },
      },
      {
        name: 'MongoDB',
        icon: {
          url: MongoIcon.src,
        },
      },
      {
        name: 'Neo4j',
        icon: {
          url: NeoIcon.src,
        },
      },
    ],
  },
];

import React, { Fragment, ReactNode } from 'react';

import { Box, Flex, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import Image from 'next/image';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

type GalleryProps = {
  photos: any[];
  textInside: ReactNode;
};

const DEFAULT_BASIC_UNIT = 8;

export const GallerySection = ({ photos, textInside }: GalleryProps) => {
  // TODO: replace
  const BASIC_UNIT =
    useBreakpointValue({
      base: 2,
      xs: 3,
      sm: 4,
      md: 6,
      lg: 8,
    }) || DEFAULT_BASIC_UNIT;
  const BASIC_UNIT_PX = BASIC_UNIT * 4;

  return (
    <SectionWrapper>
      <SectionContentWrapper paddingX={0} display="flex" justifyContent="center">
        <Grid
          minWidth="440px"
          gridTemplateColumns="repeat(4, 1fr)"
          gridTemplateAreas={`
           "a1 a2 a3 a4"
           "a5 special special a8"
           "a9 a10 a11 a12"
          `}
          gap={BASIC_UNIT}
          sx={{
            '& .gallery-photo-2': {
              paddingBottom: BASIC_UNIT * 2,
              paddingRight: BASIC_UNIT * 2,
            },
            '& .gallery-photo-3': {
              marginLeft: -BASIC_UNIT * 2,
              paddingRight: BASIC_UNIT * 2,
              paddingBottom: BASIC_UNIT,
              maxWidth: `calc(100% + ${BASIC_UNIT_PX * 2}px)`,
            },
            '& .gallery-photo-4': {
              marginLeft: -BASIC_UNIT * 2,
              maxWidth: `calc(100% + ${BASIC_UNIT_PX * 2}px)`,
              marginBottom: -BASIC_UNIT * 2,
            },
            '& .gallery-photo-5': {
              paddingRight: BASIC_UNIT,
            },
            '& .gallery-photo-6': {
              paddingLeft: BASIC_UNIT,
              paddingTop: BASIC_UNIT * 2,
              paddingBottom: BASIC_UNIT * 2,
              maxHeight: '100%',
              maxWidth: 'auto',
            },
            '& .gallery-photo-7': {
              paddingRight: BASIC_UNIT,
              paddingLeft: BASIC_UNIT,
            },
            '& .gallery-photo-8': {
              marginLeft: -BASIC_UNIT,
              marginRight: -BASIC_UNIT,
              maxWidth: `calc(100% + ${BASIC_UNIT_PX}px)`,
              marginTop: -BASIC_UNIT * 1.5,
            },
            '& .gallery-photo-9': {
              marginTop: -BASIC_UNIT,
            },
            '& .gallery-photo-10': {
              paddingRight: BASIC_UNIT,
              marginTop: -BASIC_UNIT * 2,
            },
            '& [class^="gallery-photo-"]': {
              '3xl': {
                borderRadius: '16px',
              },
            },
          }}
        >
          {photos.map((photo, index) => (
            <Fragment key={`grid-item-${index}`}>
              <GridItem
                gridArea={index < 5 ? `a${index + 1}` : `a${index + 3}`}
                alignSelf={index < 4 ? 'end' : index === 5 ? 'center' : 'start'}
                position="relative"
                height={index === 7 ? '100%' : 'initial'}
              >
                <Box as={Image} src={photo} alt="" className={`gallery-photo-${index + 1}`} />
              </GridItem>
            </Fragment>
          ))}
          <GridItem gridRow={2} gridColumn="2 / span 2" display="flex" justifyContent="center" alignItems="center">
            <Flex flexDir="column" marginTop={-BASIC_UNIT * 3}>
              {textInside}
            </Flex>
          </GridItem>
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import { ReactNode, useMemo } from 'react';

import { Heading } from '@chakra-ui/react';
import RoundHighlight from 'images/highlights/round-filled-highlight.png';
import { useTranslation } from 'next-i18next';

import { HiddenScrollbarGridContainer } from '@/components/atoms/HiddenScrollbarContainer';
import { TextDecorationPng } from '@/components/functional';
import { ArticleCardMd } from '@/components/molecules';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { useGetProposedArticlesNoTagsFilterQuery, useGetProposedArticlesQuery } from '@/graphql/generated';
import { useLocale } from '@/hooks';
import { LOCALES } from '@/utils/constants';

const RECOMMENDED_ARTICLES_LIMIT = 3;

export type RecommendedArticlesSectionProps = {
  tags: string[];
  slug: string;
  header?: ReactNode;
};

export const RecommendedArticlesSection = ({ slug, tags, header }: RecommendedArticlesSectionProps) => {
  const locale = useLocale();
  const { t } = useTranslation([LOCALES.COMMON]);

  const proposedArticles = useGetProposedArticlesQuery({
    tags,
    slug,
    locale,
  }).data?.articles?.data!;

  const isNotEnoughProposedArticles =
    Array.isArray(proposedArticles) && proposedArticles.length < RECOMMENDED_ARTICLES_LIMIT;

  // the workaround we use when server-side filtering returns not enough recommendation matching
  const proposedArticlesNoFilter = useGetProposedArticlesNoTagsFilterQuery(
    {
      slug,
      locale,
    },
    {
      enabled: isNotEnoughProposedArticles,
    },
  ).data?.articles?.data!;

  const articles = useMemo(() => {
    if (Array.isArray(proposedArticles)) {
      if (isNotEnoughProposedArticles && Array.isArray(proposedArticlesNoFilter)) {
        // merge matched articles with three random articles
        const proposedArticlesIDs = proposedArticles.map((el) => el.id);
        const articlesWithoutDublicates = proposedArticlesNoFilter.filter((el) => !proposedArticlesIDs.includes(el.id));
        return [...proposedArticles, ...articlesWithoutDublicates].slice(0, RECOMMENDED_ARTICLES_LIMIT);
      }
      // default scenario for properly linked articles
      return proposedArticles;
    }
    return [];
  }, [proposedArticles, proposedArticlesNoFilter, isNotEnoughProposedArticles]);

  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <Heading
          variant={{
            base: 'component-heading-large/lg',
            sm: 'component-heading-large/2xl',
          }}
          mb="10"
        >
          {header || (
            <>
              {t('recommendedArticles.text')}{' '}
              <TextDecorationPng image={RoundHighlight}>{t('recommendedArticles.highlighted')}</TextDecorationPng>
            </>
          )}
        </Heading>

        <HiddenScrollbarGridContainer
          templateColumns={{
            base: 'repeat(3, minmax(280px, 1fr))',
            sm: 'repeat(3,minmax(338px, 1fr))',
            lg: 'repeat(3,minmax(405px, 1fr))',
          }}
          gap={{
            base: '3',
            sm: '6',
          }}
          overflowX="auto"
          overflowY="hidden"
        >
          {articles.map((article) => {
            return article.attributes && <ArticleCardMd key={article.id} article={article.attributes} />;
          })}
        </HiddenScrollbarGridContainer>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React from 'react';

import { Show } from '@chakra-ui/react';

import { StaticBackground } from '@/components/atoms/StaticBackground';

export const TimelineExtendedBackground = () => {
  return (
    <>
      <StaticBackground
        left={{
          base: '-30%',
          lg: '-10%',
        }}
        top="-10%"
        width="687px"
        height="687px"
      />
      <Show above="sm">
        <StaticBackground
          right={{
            base: '-94%',
            lg: '-30%',
          }}
          top={{
            base: '30%',
            md: '-25%',
          }}
          backgroundColor="violet.50"
          width="1288px"
          height="1288px"
        />
        <StaticBackground left="-10%" top="40%" width="861px" height="861px" />
      </Show>
      <Show above="lg">
        <StaticBackground right="-30%" top="60%" backgroundColor="violet.50" width="1288px" height="1288px" />
        <StaticBackground left="-10%" bottom="-10%" width="687px" height="687px" />
      </Show>
    </>
  );
};

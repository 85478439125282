import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';

import { Box, Flex, Heading, Hide, Show, Text } from '@chakra-ui/react';
import Star2 from 'images/doodles/star2.png';
import Image, { StaticImageData } from 'next/image';
import Doodle from 'services/product-discovery/timeline/doodle.png';
import Planet from 'services/product-discovery/timeline/planet.png';
import Rocket from 'services/product-discovery/timeline/rocket.png';
import HistoryPoint from 'services/product-discovery/timeline/timeline-point.svg';

import { TimelineExtendedBackground } from '@/components/sections/services/sections/timeline-extended/background';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

type TimelineSectionItem = {
  number: string;
  icon: StaticImageData;
  header: string;
  description: string;
  decoration?: StaticImageData;
};

const EmptySectionDoodles = {
  '0': {
    img: Doodle,
    left: '22%',
  },
  '3': {
    img: Planet,
    left: '90%',
  },
  '4': {
    img: Rocket,
    left: '10%',
    transform: 'scale(1.65)',
  },
} as { [key: string]: { img: StaticImageData; left: string; transform?: string } };

const DECORATIONS_BELOW_SM = [0, 2, 4];

export type TimelineExtendedSectionProps = {
  items: TimelineSectionItem[];
  header: ReactNode;
};

const TABLET_VIEW_POINT_HEIGHT_SM_PX = 220;
const TABLET_VIEW_POINT_HEIGHT_XS_PX = TABLET_VIEW_POINT_HEIGHT_SM_PX * 1.2;

export const TimelineExtendedSection = ({ items, header }: TimelineExtendedSectionProps) => {
  const renderPointContent = useCallback(
    (point: TimelineSectionItem, isEven: boolean, showDecoration?: boolean) => {
      return (
        <AnimateWhenInViewWrapper>
          <Flex flexDir="column">
            <Box
              position="relative"
              mb={{
                base: 4,
                sm: 6,
              }}
            >
              <Text
                fontSize={{
                  base: '60px',
                  sm: '80px',
                }}
                h={{
                  base: '60px',
                  sm: '80px',
                }}
                mb={{
                  base: 6,
                  sm: 0,
                }}
                color="purple.100"
                fontWeight={500}
              >
                {point.number}
              </Text>
              <Text
                as="div"
                position="absolute"
                top={{
                  base: '54%',
                  sm: '74%',
                }}
                transform="translateY(-50%)"
                color="violet.500"
                variant={{
                  base: 'text-xl/lineHeight-7/font-medium',
                  sm: 'text-3xl/lineHeight-9/font-medium',
                }}
                sx={{
                  [isEven ? 'right' : 'left']: '10%',
                }}
              >
                {point.header}
                {point.decoration && showDecoration && (
                  <Box
                    position="absolute"
                    as={Image}
                    src={point.decoration}
                    alt=""
                    height={{
                      base: '40px',
                      sm: '56px',
                    }}
                    maxW="unset"
                    w="auto"
                    top="-70%"
                    sx={{
                      [isEven ? 'left' : 'right']: '-80px',
                    }}
                  />
                )}
              </Text>
            </Box>
            <Text maxW="362px" variant="text-md/lineHeight-6/font-normal" color="purple.400">
              {point.description}
            </Text>
          </Flex>
        </AnimateWhenInViewWrapper>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  );

  const renderPointAboveSm = useMemo(() => {
    const points: ReactElement[] = [];

    items.map((point, index) => {
      const isEven = index % 2 !== 0;
      const isLastPoint = index === items.length - 1;

      const pointTemplate = (
        <Flex
          key={`timeline-point-${index}`}
          flexDir="column"
          alignItems={isEven ? 'end' : 'start'}
          sx={{
            'h2, h3, p': {
              textAlign: isEven ? 'right' : 'left',
            },
          }}
          minH="214px"
          w="100%"
        >
          <Flex
            flexDir="column"
            pb={6}
            pt={2}
            position="relative"
            h={{
              xs: `${TABLET_VIEW_POINT_HEIGHT_XS_PX}px`,
              sm: `${TABLET_VIEW_POINT_HEIGHT_SM_PX}px`,
            }}
          >
            {renderPointContent(point, isEven, true)}
            <Box
              position="absolute"
              left={isEven ? 'calc(100% + 32px)' : -12}
              top="24%"
              w={7}
              h={7}
              sx={{
                '& svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <HistoryPoint />
            </Box>
            {!isLastPoint && (
              <Flex
                w="2px"
                h={{
                  xs: '84px',
                  sm: '100%',
                }}
                bgColor="purple.200"
                position="absolute"
                left={isEven ? 'calc(100% + 45px)' : '-35px'}
                top="45%"
              />
            )}
          </Flex>
        </Flex>
      );

      points.push(pointTemplate);
    });

    return points;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <SectionWrapper>
      <SectionContentWrapper paddingY={undefined} pt={{ base: 16, sm: 20, md: 36 }} pb={{ base: 10 }}>
        <TimelineExtendedBackground />
        <Heading
          variant={{
            base: 'component-heading-large/xl',
            sm: 'component-heading-large/2xl',
            md: 'component-heading-large/3xl',
          }}
          color="purple.500"
          mb={{
            base: 20,
            md: 40,
          }}
          textAlign="center"
        >
          {header}
        </Heading>

        <Hide above="sm">
          <Flex flexDir="column">
            {items.map((point, index) => {
              const isLastPoint = index === items.length - 1;
              const showDecoration = DECORATIONS_BELOW_SM.includes(index);

              return (
                <Flex pb={8} key={`timeline-point-${point.number}`}>
                  <Flex flexDir="column" alignItems="center" mr={4} position="relative">
                    <Box
                      w={7}
                      h={7}
                      sx={{
                        '& svg': {
                          width: '100%',
                          height: '100%',
                        },
                      }}
                      mb={3}
                      mt={8}
                    >
                      <HistoryPoint />
                    </Box>
                    {!isLastPoint && (
                      <Flex w="2px" position="absolute" top="76px" h="calc(100% - 30px)" bgColor="purple.200" />
                    )}
                  </Flex>

                  {renderPointContent(point, false, showDecoration)}
                </Flex>
              );
            })}
            <Box
              position="absolute"
              as={Image}
              src={Star2}
              alt=""
              w="36px"
              bottom="3%"
              right={{
                base: '10%',
                xs: '50%',
              }}
              transform="rotate(-30deg)"
            />
          </Flex>
        </Hide>

        <Show above="sm">
          <Flex
            mb={-14}
            px={{
              base: 4,
              sm: 12,
            }}
          >
            <Flex flexDir="column" w="100%" position="relative">
              {renderPointAboveSm.map((point, index) => {
                const isEven = index % 2 === 0;
                const doodle = String(index) in EmptySectionDoodles && EmptySectionDoodles[String(index)];
                const doodleElement = doodle ? (
                  <Box
                    as={Image}
                    src={doodle.img}
                    alt=""
                    w="90px"
                    h="fit-content"
                    left={doodle.left}
                    position="absolute"
                    transform={doodle.transform}
                  />
                ) : null;

                return (
                  <Flex
                    mb={{
                      base: 10,
                      md: 16,
                    }}
                    key={`timeline-point-${index}`}
                  >
                    <Flex flex={1} pr={10}>
                      {!isEven ? point : doodleElement}
                    </Flex>
                    <Flex flex={1} pl={10}>
                      {isEven ? point : doodleElement}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Show>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

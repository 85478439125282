import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

const hiddenScrollbarStyle = `
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HiddenScrollbarContainer = styled(Flex)`
  ${hiddenScrollbarStyle}
`;

export const HiddenScrollbarGridContainer = styled(Grid)`
  ${hiddenScrollbarStyle}
`;

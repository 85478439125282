import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';

type TimelineImageProps = {
  icon: StaticImageData;
  className?: string;
  chakraStyles?: BoxProps;
};

export const TimelineImage = ({ icon, chakraStyles, className }: TimelineImageProps) => {
  return (
    <Box
      as={Image}
      src={icon}
      alt=""
      h={{
        base: '36px',
        xs: '50px',
        md: '56px',
      }}
      w="auto"
      {...chakraStyles}
      className={className}
    />
  );
};

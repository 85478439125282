import React from 'react';

import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { StaticImageData } from 'next/image';
import Image from 'next/image';

export type GifSectionMenuItemProps = {
  icon: StaticImageData;
  text: string;
  active?: boolean;
} & FlexProps;

export const GifSectionMenuItem = ({ icon, active, text, ...style }: GifSectionMenuItemProps) => {
  return (
    <Flex alignItems="center" gap={5} {...style}>
      <Box as={Image} src={icon} alt="menu icon" h="49px" w="54px" />
      <Text
        color="purple.500"
        as="h2"
        variant={{
          base: `text-md/lineHeight-6/${active ? 'font-medium' : 'font-normal'}`,
          md: `text-xl/lineHeight-7/${active ? 'font-medium' : 'font-normal'}`,
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { TechnologyItemImage } from '@/components/atoms/TechnologyHeader/copy';
import { getImageAlt, getImageSrcWithBlurhash } from '@/utils/images';

type TechnologyProps = {
  technologyName: string;
  technologyImage: TechnologyItemImage['icon'];
} & FlexProps;

export const Technology = ({ technologyName, technologyImage, ...flexProps }: TechnologyProps) => {
  return (
    <Flex
      alignItems="center"
      pl={{ base: 3, sm: 4 }}
      pr={{ base: 5, sm: 12 }}
      py={{ base: 3, sm: 4 }}
      w="fit-content"
      backgroundColor="white"
      borderRadius="20px"
      gap={4}
      {...flexProps}
    >
      <Box borderRadius="12px" overflow="hidden" minW="40px" minH="40px" position={'relative'}>
        <Image
          {...getImageSrcWithBlurhash(technologyImage)}
          alt={getImageAlt(technologyImage, technologyName)}
          objectFit="contain"
          layout="fill"
        />
      </Box>
      <Text
        color="purple.500"
        variant={{
          base: 'text-sm/lineHeight-5/font-medium',
          lg: 'text-md/lineHeight-6/font-medium',
        }}
        whiteSpace={{ lg: 'nowrap' }}
      >
        {technologyName}
      </Text>
    </Flex>
  );
};

import React, { ReactNode } from 'react';

import { Box, BoxProps, Text } from '@chakra-ui/react';

type TechnologyHeaderProps = {
  children: ReactNode;
} & BoxProps;

export const TechnologyHeader = ({ children, ...style }: TechnologyHeaderProps) => {
  return (
    <Text as={Box} variant="text-xl/lineHeight-7/font-book" color="purple.300" textTransform="uppercase" {...style}>
      {children}
    </Text>
  );
};

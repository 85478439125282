import React, { ReactNode } from 'react';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { VideoWrapperWithFallback } from '@/components/wrappers/VideoWrapper/VideoWrapperWithFallback';
import { StrapiMediaAttributes } from '@/typings/strapi';
import { HEADER_HEIGHT } from '@/utils/constants';

export type VideoBannerSectionProps = {
  header: ReactNode;
  coverImageFallback: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
  coverVideo: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
  buttonLabel?: string | null;
  buttonLink?: string | null;
};

export const VideoBannerSection = ({
  header,
  coverVideo,
  coverImageFallback,
  buttonLabel,
  buttonLink,
}: VideoBannerSectionProps) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper paddingY={undefined} paddingX={undefined}>
        <Box position="relative">
          <Flex
            justifyContent="center"
            alignItems="center"
            position="absolute"
            width="100%"
            height="100%"
            flexDirection="column"
          >
            <Heading
              as="h1"
              variant={{
                base: 'component-heading-large/xl',
                xs: 'component-heading-large/2xl',
                md: 'component-heading-large/4xl',
              }}
              textAlign="center"
              color="white"
              zIndex={2}
              mt={-12}
              maxWidth={'600px'}
            >
              {header}
            </Heading>
            {buttonLabel && buttonLink && (
              <Button mt={4} url={buttonLink} variant={'light'} maxW="300px" justifySelf="flex-end">
                <Text as="span" maxW="100%" overflow="hidden" textOverflow="ellipsis">
                  {buttonLabel}
                </Text>
              </Button>
            )}
            <Box
              sx={{
                background: '#3725C1',
                opacity: 0.7,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Flex>

          <VideoWrapperWithFallback
            width={1200}
            height={1000}
            coverImageFallback={coverImageFallback}
            coverVideo={coverVideo}
            sx={{
              '&': {
                height: `calc(100vh - ${HEADER_HEIGHT})`,
              },
            }}
          />
        </Box>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React from 'react';

import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { BaseBreakpointConfig } from '@chakra-ui/theme-tools';

export type AvatarSize = 'sm' | 'md' | 'lg';

export type QuotationAuthorProps = {
  name: string;
  role: string;
  avatar: string;
  company?: string;
  size?: AvatarSize;
  fontVariant?: string | Partial<BaseBreakpointConfig>;
};

export const QuotationAuthor = ({
  avatar,
  role,
  company,
  name,
  size = 'sm',
  fontVariant = 'text-xs/lineHeight-4/font-normal/0.75rem',
}: QuotationAuthorProps) => {
  return (
    <Flex alignItems="center">
      <Box borderRadius="full" overflow="hidden" flexShrink="0" position="relative">
        <Avatar bg="violet.500" name={name} src={avatar} size={size} />
      </Box>
      <Box pl={2}>
        <Text
          variant={
            size === 'lg'
              ? {
                  base: 'text-sm/lineHeight-5/font-medium',
                  md: 'text-2xl/lineHeight-8/font-semibold',
                }
              : {
                  base: 'text-xs/lineHeight-4/font-medium/0.75rem',
                  md: 'text-sm/lineHeight-5/font-medium',
                }
          }
          color="purple.500"
        >
          {name}
        </Text>
        <Text variant={fontVariant} color="purple.400">
          {role}{' '}
          {company && (
            <>
              @ <strong>{company}</strong>
            </>
          )}
        </Text>
      </Box>
    </Flex>
  );
};

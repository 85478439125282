import React, { ReactNode } from 'react';

import { Box, Flex, useMediaQuery, useQuery } from '@chakra-ui/react';
import Image from 'next/image';
import Circle1 from 'services/product-design/faces/circle1.png';
import Circle2 from 'services/product-design/faces/circle2.png';

import { FacesSectionItem, HeaderStars } from '@/components/sections/services/sections';
import { FacesSectionItem as FacesSectionItemType } from '@/components/sections/services/typings';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

type FacesSectionProps = {
  items: FacesSectionItemType[];
  header?: ReactNode;
};

export const TeamFaces = ({ items, header }: FacesSectionProps) => {
  // TODO: replace when we decide on the way to deal with media queries, ideally - add device type detection
  const [isLargerThanSm] = useMediaQuery(useQuery({ above: 'sm' }));

  return (
    <SectionWrapper>
      <SectionContentWrapper display="flex" alignItems="center" flexDir="column">
        {header && <HeaderStars text={header} />}
        <Flex
          flexDir={{
            base: 'column',
            sm: 'row',
          }}
          justifyContent={{
            base: 'space-evenly',
            md: 'center',
          }}
          flexWrap="wrap"
          maxW="1100px"
          gap={{
            base: 12,
            md: 14,
            lg: 20,
            xl: 24,
          }}
          marginX={{
            md: -4,
          }}
        >
          {items.map((item, index) => (
            <Box position="relative" key={`faces-${index}`}>
              <Box
                as={Image}
                src={index < 3 ? Circle1 : Circle2}
                alt=""
                position="absolute"
                transform={{
                  base: 'translateX(-50%)',
                  sm: 'translateX(-50%) scale(1.14)',
                  md: 'translateX(-50%)',
                }}
                left="50%"
                height={{
                  base: '116px',
                  xs: '160px',
                  sm: '116px',
                  md: '180px',
                }}
                width="auto"
              />
              <FacesSectionItem
                {...item}
                isMobile={!isLargerThanSm}
                isLast={index === items.length - 1}
                showExtraBubbles={false}
                style={{
                  '& .initial-image, & .hover-image': {
                    height: {
                      base: '110px',
                      xs: '150px',
                      sm: '126px',
                      md: '170px',
                    },
                  },
                }}
              />
            </Box>
          ))}
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React, { ElementRef, ReactNode, useRef } from 'react';

import { BoxProps, Card, Flex, Heading, Show, Text, useMediaQuery, useQuery } from '@chakra-ui/react';

import { Slider } from '@/components/functional';
import { SliderControls } from '@/components/functional/SliderControls/SliderControls';
import { SliderIconsItem } from '@/components/sections/services/sections';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

import type { SliderSectionCardItem } from '../../typings';

export type SliderExtendedSectionProps = {
  header: ReactNode;
  subheader: ReactNode;
  items: SliderSectionCardItem[];
  stylesWrapper?: BoxProps;
};

export const SliderExtendedSection = ({ header, subheader, items, stylesWrapper }: SliderExtendedSectionProps) => {
  const sliderRef = useRef<ElementRef<typeof Slider>>(null);
  const [isAboveSM] = useMediaQuery(useQuery({ above: 'sm' }));

  return (
    <SectionWrapper backgroundColor="violet.100" zIndex={0} overflow="hidden">
      <SectionContentWrapper
        paddingY={undefined}
        pt={{
          base: 0,
          sm: 4,
          md: 10,
        }}
        pb={{ base: 16, sm: 20 }}
        {...stylesWrapper}
      >
        <Flex
          gap={{
            base: 2,
            sm: 10,
          }}
          flexDir={{
            base: 'column',
            sm: 'row',
          }}
        >
          <Flex flex={1} flexDir="column">
            <Heading
              variant={{
                base: 'component-heading-large/lg',
                sm: 'component-heading-large/2xl',
              }}
              color="purple.500"
              mb={{
                base: 6,
                md: 8,
              }}
            >
              {header}
            </Heading>
            <Text
              variant={{
                base: 'text-md/lineHeight-6/font-normal',
                sm: 'text-md/lineHeight-6/font-normal',
                md: 'text-xl/lineHeight-7/font-normal',
              }}
              color="purple.500"
              mb={{
                base: 10,
                sm: 12,
                md: 10,
              }}
            >
              {subheader}
            </Text>
            <Show above="sm">
              <SliderControls
                onSlidePrev={() => sliderRef.current?.onPrev()}
                onSlideNext={() => sliderRef.current?.onNext()}
                variant="dark"
                justifyContent="flex-start"
              />
            </Show>
          </Flex>
          <Flex
            maxW={{
              base: 'unset',
              sm: '50%',
              lg: '56%',
            }}
            position="relative"
            mx={{
              base: -4,
              sm: 0,
            }}
            mb={{
              base: 0,
              md: 9,
            }}
          >
            <Slider ref={sliderRef} snapDirection={isAboveSM ? 'start' : 'center'} gapPX={24} pl={{ base: 4, sm: 0 }}>
              {items.map(
                (item, index) =>
                  item && (
                    <Card
                      key={`product_d_slider_${index}`}
                      minW={{
                        base: '256px',
                        sm: '288px',
                        md: '337px',
                      }}
                      borderRadius="24px"
                      px={{
                        base: 6,
                        md: 8,
                      }}
                      py={{
                        base: 6,
                        sm: 8,
                        md: 10,
                      }}
                      backgroundColor="white"
                    >
                      <SliderIconsItem {...item} />
                    </Card>
                  ),
              )}
            </Slider>
          </Flex>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

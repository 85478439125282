import React, { ReactNode } from 'react';

import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Flex, Heading, Text } from '@chakra-ui/react';

import { Button } from '@/components/atoms';
import { PlusMinusIconAnimated } from '@/components/atoms/Icons/plus';
import { DynamicTextWithDecoration } from '@/components/sections/dynamic-blocks/DynamicTextWithDecoration';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicFaqFragment } from '@/graphql/generated';
import { TextDecorationIcons } from '@/utils/text-decorations';

export type QAItem = {
  header: string;
  text: ReactNode;
};

export type QASectionProps = {
  header: DynamicFaqFragment['header'] | ReactNode;
  items: QAItem[];
  isDarkMode?: boolean;
  textDecoration?: TextDecorationIcons;
};

export const QASection = ({ header, items, textDecoration, isDarkMode }: QASectionProps) => {
  return (
    <SectionWrapper backgroundColor="violet.500">
      <SectionContentWrapper>
        <Flex
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          gap={{
            base: 14,
            sm: 24,
            md: 14,
          }}
        >
          <Flex flex={1}>
            <Heading
              as="h3"
              variant={{
                base: 'component-heading-large/lg',
                sm: 'component-heading-large/xl',
                md: 'component-heading-large/2xl',
              }}
              w={{
                base: '100%',
                lg: '80%',
              }}
              color="white"
              whiteSpace="pre-line"
              zIndex={0}
            >
              {/* TODO: here always should come string from CMS but QASection is in pages which aren't moved to CMS yet */}
              {typeof header === 'string' ? (
                <DynamicTextWithDecoration isDarkMode={isDarkMode} {...textDecoration}>
                  {header}
                </DynamicTextWithDecoration>
              ) : (
                header
              )}
            </Heading>
          </Flex>
          <Flex
            flex={{
              base: 1.5,
              lg: 1,
            }}
            mt={-12}
          >
            <Accordion width="100%" allowMultiple={true} allowToggle={true}>
              {items.map((item, index) => (
                <AccordionItem key={`accordion-item-${index}`}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton as="div">
                        <Heading
                          variant={{
                            base: 'text-lg/lineHeight-7/font-semibold',
                            sm: 'component-article-headline/2xl',
                            md: 'component-article-headline/3xl',
                          }}
                          color="white"
                          textAlign="start"
                        >
                          {item.header}
                        </Heading>
                        <Button
                          label=""
                          aria-label="toggle accordion item"
                          leftIcon={<PlusMinusIconAnimated plus={!isExpanded} />}
                          variant="sliderLight"
                          iconSpacing={0}
                          minH="48px"
                          minW="48px"
                          ml={2}
                        />
                      </AccordionButton>
                      <AccordionPanel
                        maxWidth={{
                          base: '100%',
                          md: 'calc(100% - 80px)',
                        }}
                      >
                        <Text as="div" variant="text-md/lineHeight-6/font-normal" color="white">
                          {item.text}
                        </Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React, { ElementRef, ReactNode, useRef } from 'react';

import { Box, Card, Show, useMediaQuery, useQuery } from '@chakra-ui/react';

import { Slider } from '@/components/functional';
import { SliderControls } from '@/components/functional/SliderControls/SliderControls';
import {
  GridSectionItem,
  GridSectionItemProps,
  HeaderStars,
  MShapedBackground,
} from '@/components/sections/services/sections';
import '@/components/sections/services/sections/atoms/grid-item/GridSectionItem';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

export type SliderSectionProps = {
  header: ReactNode;
  items: GridSectionItemProps[];
};

export const SliderSection = ({ header, items }: SliderSectionProps) => {
  const sliderRef = useRef<ElementRef<typeof Slider>>(null);
  const [isAboveXS] = useMediaQuery(useQuery({ above: 'xs' }));

  return (
    <SectionWrapper backgroundColor="purple.600" zIndex={0}>
      <SectionContentWrapper>
        <MShapedBackground />
        <HeaderStars
          text={header}
          color="white"
          mt={{
            base: 2,
            sm: 4,
            md: 2.5,
          }}
          mb={{
            sm: 9,
            md: 1,
          }}
        />
        <Show above="sm">
          <SliderControls
            onSlidePrev={() => sliderRef.current?.onPrev()}
            onSlideNext={() => sliderRef.current?.onNext()}
            variant="light"
          />
        </Show>
        <Box pt={{ base: 8 }} mx={{ base: -5, xs: 0 }}>
          <AnimateWhenInViewWrapper>
            <Slider
              ref={sliderRef}
              snapDirection={isAboveXS ? 'start' : 'center'}
              pl={{ base: 4, xs: 0 }}
              gapPX={isAboveXS ? 24 : 20}
            >
              {items.map(
                (item, index) =>
                  item && (
                    <Card
                      key={`product_d_slider_${index}`}
                      backgroundColor="white"
                      minW={{
                        base: '256px',
                        sm: '340px',
                        md: '410px',
                      }}
                      borderRadius="24px"
                      px={{
                        base: 6,
                        md: 8,
                      }}
                      py={{
                        base: 6,
                        sm: 8,
                        md: 10,
                      }}
                    >
                      <GridSectionItem
                        iconHeight={{
                          base: '64px',
                          md: '80px',
                        }}
                        iconStyle={{
                          order: -1,
                        }}
                        heading={item.heading}
                        description={item.description}
                        buttonLabel={item.buttonLabel}
                        buttonLink={item.buttonLink}
                        icon={item.icon}
                      />
                    </Card>
                  ),
              )}
            </Slider>
          </AnimateWhenInViewWrapper>
        </Box>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React from 'react';

import { Box, BoxProps, ChakraStyledOptions, Flex, FlexProps, GridItem, Heading, Text } from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';

import { Button } from '@/components/atoms';
import { AnimateWhenInViewWrapper } from '@/components/wrappers';

export type GridSectionItemProps = {
  heading: string;
  description: string;
  icon: StaticImageData | string;
  iconHeight?: string | ChakraStyledOptions;
  iconStyle?: BoxProps;
  colorVariant?: 'light' | 'dark';
  itemStyle?: FlexProps;
  styleVariant?: 'default' | 'alternative';
  buttonLabel?: string;
  buttonLink?: string;
};

export const GridSectionItem = ({
  icon,
  iconHeight,
  iconStyle,
  heading,
  description,
  buttonLabel,
  buttonLink,
  colorVariant,
  styleVariant = 'default',
}: GridSectionItemProps) => {
  const isDarkVariant = colorVariant === 'dark';
  const isAlternativeVariant = styleVariant === 'alternative';

  return (
    <GridItem
      h="100%"
      sx={{
        '.grid-section-item-card': {
          height: '100%',
        },
        '.grid-section-item-card > div': {
          height: '100%',
        },
      }}
    >
      <AnimateWhenInViewWrapper className="grid-section-item-card">
        <Flex flexDir="column" justify="space-between" h="100%">
          <Flex flexDir="column" alignItems={isAlternativeVariant ? 'center' : 'start'}>
            <Heading
              variant={
                isAlternativeVariant
                  ? 'text-xl/lineHeight-7/font-medium'
                  : {
                      base: 'component-article-headline/xl',
                      md: 'component-article-headline/2xl',
                    }
              }
              color={isDarkVariant ? 'white' : 'purple.500'}
              mt={{
                base: 6,
                md: isAlternativeVariant ? 4 : 8,
              }}
              mb={{
                base: 2,
                md: 6,
                lg: 4,
              }}
              textAlign={isAlternativeVariant ? 'center' : 'left'}
              className="grid-section-item-header"
            >
              {heading}
            </Heading>
            <Box h={iconHeight || '20px'} width="100%" {...iconStyle} position="relative">
              <Image
                src={icon}
                alt=""
                fill
                style={{
                  objectFit: 'contain',
                  objectPosition: 'left',
                }}
              />
            </Box>
            <Text
              variant={isAlternativeVariant ? 'text-sm/lineHeight-5/font-normal' : 'text-md/lineHeight-6/font-normal'}
              color={isDarkVariant ? 'white' : 'purple.400'}
              className="grid-section-item-text"
              textAlign={isAlternativeVariant ? 'center' : 'left'}
            >
              {description}
            </Text>
          </Flex>
          {buttonLabel && buttonLink && (
            <Flex justifyContent="center">
              <Button
                mt={4}
                url={buttonLink}
                variant={isDarkVariant ? 'dark' : 'light'}
                maxW={{
                  base: '256px',
                  sm: '340px',
                  md: '410px',
                }}
                justifySelf="flex-end"
              >
                <Text as="span" maxW="100%" overflow="hidden" textOverflow="ellipsis">
                  {buttonLabel}
                </Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </AnimateWhenInViewWrapper>
    </GridItem>
  );
};

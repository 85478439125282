import React, { ReactNode } from 'react';

import { Box, ChakraStyledOptions, Flex, Grid, GridItem, Heading, Hide, Show } from '@chakra-ui/react';

import { OShapedBackground, TimelineImage, TimelineSectionItem } from '@/components/sections/services/sections';
import { TimelineSectionItem as TimelineSectionItemType } from '@/components/sections/services/typings';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

export type TimelineSectionProps = {
  items: TimelineSectionItemType[];
  header?: ReactNode;
  gridColumns?: number;
  itemStyle?: ChakraStyledOptions;
};

export const TimelineSection = ({ items, header, gridColumns = 3, itemStyle }: TimelineSectionProps) => {
  return (
    <SectionWrapper backgroundColor="purple.600" overflow="hidden">
      <SectionContentWrapper display="flex" flexDir="column">
        <OShapedBackground />
        <Box zIndex={1}>
          {header && (
            <Heading
              variant={{
                base: 'component-heading-large/xl',
                xs: 'component-heading-large/2xl',
                sm: 'component-heading-large/3xl',
              }}
              color="white"
              maxW={{
                base: '100%',
                xs: '80%',
                md: '60%',
              }}
              mb={{
                base: 16,
                sm: 14,
                md: 16,
              }}
            >
              {header}
            </Heading>
          )}
        </Box>

        <Flex
          zIndex={1}
          justifyContent={{
            base: 'start',
            xl: 'center',
          }}
        >
          <Hide above="md">
            <Grid
              gridTemplateColumns={{
                base: '60px 1fr',
                xs: '80px 1fr',
              }}
              gridRowGap={{
                base: 10,
                sm: 18,
              }}
              gridColumnGap={{
                base: 2,
                sm: 4,
              }}
            >
              {items.map((item, index) => (
                <GridItem
                  gridColumn={1}
                  gridRow={index + 1}
                  display="flex"
                  justifyContent="center"
                  className="timeline-section-item"
                  sx={itemStyle}
                  key={`timeline-section-item-${item.number}`}
                >
                  <Flex
                    alignItems="center"
                    flexDir="column"
                    mt={{
                      base: -1,
                      xs: -3,
                    }}
                    h="100%"
                  >
                    <TimelineImage icon={item.icon} className={`timeline-img-${index}`} />
                    {index !== items.length - 1 && (
                      <Box
                        h={{
                          base: '100%',
                          xs: '106%',
                        }}
                        w="5px"
                        mb={{
                          base: -10,
                          sm: -18,
                        }}
                        backgroundImage="radial-gradient(white 1px, transparent 26%)"
                        backgroundSize="10px 6px"
                      />
                    )}
                  </Flex>
                </GridItem>
              ))}
              {items.map((item, index) => (
                <GridItem gridColumn={2} gridRow={index + 1} key={`timeline-section-item-${item.number}`}>
                  <AnimateWhenInViewWrapper>
                    <TimelineSectionItem {...item} isLast={index === items.length - 1} index={index} />
                  </AnimateWhenInViewWrapper>
                </GridItem>
              ))}
            </Grid>
          </Hide>

          <Show above="md">
            <Grid
              columnGap={{
                base: 10,
                md: 12,
                lg: 16,
              }}
              rowGap={{
                base: 10,
                md: 14,
                lg: 20,
              }}
              maxW={{
                base: '1200px',
                xl: 'unset',
              }}
              gridTemplateColumns={`repeat(${gridColumns}, 1fr)`}
            >
              {items.map((item, index) => (
                <AnimateWhenInViewWrapper key={`timeline-section-item-${item.number}`}>
                  <TimelineSectionItem
                    {...item}
                    isLast={(index + 1) % gridColumns === 0 || index === items.length - 1}
                    index={index}
                  />
                </AnimateWhenInViewWrapper>
              ))}
            </Grid>
          </Show>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

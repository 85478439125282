import React, { ReactNode } from 'react';

import { Box, BoxProps, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

export type ContactPersonSectionProps = {
  image?: string | StaticImageData;
  header: ReactNode;
  paragraphs: ReactNode[];
  imageStyle?: FlexProps;
  wrapperStyle?: BoxProps;
  flip?: boolean;
  buttonAction?: () => void;
  buttonUrl?: string | null;
  buttonText?: string | null;
};

export const ContactPersonSection = ({
  header,
  image,
  paragraphs,
  imageStyle,
  flip,
  buttonAction,
  buttonUrl,
  buttonText,
  wrapperStyle,
}: ContactPersonSectionProps) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper {...wrapperStyle}>
        <Flex
          flexDir={{
            base: 'column',
            sm: 'row',
          }}
          gap={{
            sm: 6,
            md: 10,
            lg: 40,
          }}
        >
          {image && (
            <Flex
              flex={1}
              justifyContent={{
                base: 'center',
                lg: 'flex-end',
              }}
              mb={
                flip
                  ? 0
                  : {
                      base: 10,
                      sm: 0,
                    }
              }
              alignItems="center"
            >
              <Box
                w={{
                  base: '264px',
                  sm: '288px',
                  md: '426px',
                  lg: '612px',
                }}
                h={{
                  base: '264px',
                  sm: '288px',
                  md: '426px',
                }}
                sx={imageStyle}
                position="relative"
                borderRadius="2xl"
                overflow="hidden"
              >
                <Image
                  style={{
                    objectFit: 'contain',
                  }}
                  fill={true}
                  src={image}
                  alt={typeof header === 'string' ? header : ''}
                />
              </Box>
            </Flex>
          )}
          <Flex
            flex={1}
            order={flip ? -1 : 0}
            justifyContent="center"
            flexDir="column"
            alignItems={{
              base: 'start',
              xs: 'center',
              sm: 'start',
            }}
            mb={
              flip
                ? {
                    base: 10,
                    sm: 0,
                  }
                : 0
            }
          >
            <Heading
              variant={{
                base: 'component-heading-large/lg',
                sm: 'component-heading-large/2xl',
                md: 'component-heading-large/4xl',
              }}
              color="purple.500"
              mb={{
                base: 4,
                sm: 6,
              }}
              textAlign={{
                base: 'left',
                xs: 'center',
                sm: 'left',
              }}
              w="100%"
            >
              {header}
            </Heading>
            <Flex flexDir="column">
              <Flex flexDir="column" mb={6} maxW="430px">
                {paragraphs.map((p, index) => (
                  <Text
                    key={`contact-section-p-${index}`}
                    variant={{
                      base: 'text-md/lineHeight-6/font-normal',
                      md: 'text-xl/lineHeight-7/font-normal',
                    }}
                    sx={{
                      strong: {
                        fontWeight: '600',
                      },
                      '&:not(:last-of-type)': {
                        marginBottom: 8,
                      },
                    }}
                    textAlign={{
                      base: 'start',
                      xs: 'center',
                      sm: 'start',
                    }}
                    color="purple.400"
                  >
                    {p}
                  </Text>
                ))}
              </Flex>
              <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                <Button
                  label={buttonText || 'Schedule a call'}
                  variant="light"
                  size="lg"
                  onClick={buttonAction}
                  url={buttonUrl}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React, { ReactNode } from 'react';

import { Box, Flex, FlexProps, Heading } from '@chakra-ui/react';
import Star from 'images/doodles/star.png';
import Image from 'next/image';

type HeaderStarsProps = {
  text: ReactNode;
  color?: string;
} & FlexProps;

export const HeaderStars = ({ text, color = 'purple.500', ...styles }: HeaderStarsProps) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      width="100%"
      mb={{
        base: 10,
        sm: 20,
      }}
      sx={styles}
    >
      <Heading
        position="relative"
        as="h2"
        variant={{
          base: 'component-heading-large/lg',
          sm: 'component-heading-large/xl',
          md: 'component-heading-large/2xl',
        }}
        color={color}
        textAlign="center"
        maxW={{
          base: '60%',
          xs: '40%',
          sm: '60%',
          md: '70%',
          lg: '1040px',
        }}
        mb={{
          base: 9,
          lg: 16,
        }}
      >
        {text}
        <Box
          as={Image}
          src={Star}
          alt=""
          width="auto"
          height={{
            base: '35px',
            sm: '50px',
            md: '64px',
          }}
          zIndex={0}
          position="absolute"
          right={{
            base: '86%',
            sm: -16,
          }}
          top={{
            base: -4,
            sm: -12,
          }}
        />
        <Box
          as={Image}
          src={Star}
          alt=""
          width="auto"
          height={{
            base: '29px',
            sm: '36px',
            md: '43px',
          }}
          zIndex={0}
          position="absolute"
          left={{
            base: '90%',
            sm: -12,
          }}
          bottom={{
            base: -6,
            sm: -10,
          }}
        />
      </Heading>
    </Flex>
  );
};

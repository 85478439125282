import React, { useState } from 'react';

import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { FilterButton } from '@/components/atoms';
import { HiddenScrollbarContainer } from '@/components/atoms/HiddenScrollbarContainer';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { VideoWrapperWithFallback } from '@/components/wrappers/VideoWrapper/VideoWrapperWithFallback';
import { StrapiMediaAttributes } from '@/typings/strapi';

export type MediaItem = {
  id: string;
  name: string;
  coverImageFallback: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
  coverVideo: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
};

export type MediaSectionProps = {
  items: MediaItem[];
  style?: BoxProps;
  videoStyle?: BoxProps;
  maxContainerWidth?: string;
  ratio?: number;
};

const DEFAULT_ASPECT_RATIO = 0.58;

export const MediaSection = ({ items, style, ratio, videoStyle, maxContainerWidth }: MediaSectionProps) => {
  const [selectedItem, setSelectedItem] = useState<MediaItem>(items[0]);
  const aspectRatio = ratio || DEFAULT_ASPECT_RATIO;
  const maxWidth = maxContainerWidth || '1280px';

  return (
    <SectionWrapper>
      <SectionContentWrapper paddingY={{ base: 4, sm: 20 }} {...style}>
        <Flex borderRadius="30px" position="relative" flexDir="column" alignItems="center">
          <Flex
            width={{
              base: '100vw',
              sm: '100%',
            }}
            justifyContent={{
              base: 'start',
              sm: 'center',
            }}
          >
            <HiddenScrollbarContainer
              py={{
                base: 8,
                sm: 7,
              }}
              px={{
                base: 8,
                lg: 0,
              }}
              mx={{
                base: -4,
              }}
              justifyContent={{
                base: 'start',
                sm: 'center',
              }}
              position={{
                base: 'relative',
                sm: 'absolute',
              }}
              overflowX={{
                base: 'scroll',
                sm: 'auto',
              }}
              zIndex={2}
            >
              {items.map((item) => (
                <Box mr={2} key={item.name}>
                  <FilterButton
                    label={item.name}
                    onClick={() => setSelectedItem(item)}
                    selected={selectedItem.id === item.id}
                    buttonTextStyle={{
                      maxWidth: '180px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  />
                </Box>
              ))}
            </HiddenScrollbarContainer>
          </Flex>

          <Box
            width="100%"
            maxW={maxWidth}
            overflow="hidden"
            height={{
              base: `calc((100vw - 40px) * ${aspectRatio})`,
              sm: `calc((100vw - 64px) * ${aspectRatio})`,
              lg: `calc((100vw - 160px) * ${aspectRatio})`,
            }}
            maxH={`calc(${maxWidth} * ${aspectRatio})`}
            position="relative"
          >
            <AnimatePresence>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{ duration: 0.5 }}
                key={`animated-menu-item-${selectedItem.id}`}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  borderRadius: '30px',
                }}
              >
                <VideoWrapperWithFallback
                  width={1000}
                  height={800}
                  coverImageFallback={selectedItem.coverImageFallback}
                  coverVideo={selectedItem.coverVideo}
                  sx={{
                    '& video': {
                      borderRadius: '30px',
                    },
                    ...videoStyle,
                  }}
                />
              </motion.div>
            </AnimatePresence>
          </Box>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

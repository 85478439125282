import React, { ReactNode } from 'react';

import { BoxProps, Grid, GridProps, Heading, HeadingProps } from '@chakra-ui/react';

import { MShapedBackground } from '@/components/sections/services/sections';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

type GridSectionProps = {
  items: ReactNode[];
  header?: ReactNode;
  colorVariant?: 'light' | 'dark';
  headerStyle?: HeadingProps;
  wrapperStyle?: BoxProps;
} & GridProps;

export const GridSection = ({
  items,
  header,
  colorVariant = 'dark',
  headerStyle,
  wrapperStyle,
  ...gridProps
}: GridSectionProps) => {
  const isDarkVariant = colorVariant === 'dark';

  return (
    <SectionWrapper backgroundColor={isDarkVariant ? 'purple.600' : 'none'} zIndex={0}>
      <SectionContentWrapper sx={wrapperStyle}>
        {isDarkVariant && <MShapedBackground />}
        {header && (
          <Heading
            variant={{
              base: 'component-heading-large/lg',
              sm: 'component-heading-large/xl',
              md: 'component-heading-large/2xl',
            }}
            color={isDarkVariant ? 'white' : 'purple.500'}
            mb={{
              base: 14,
              sm: 16,
              md: 20,
            }}
            {...headerStyle}
          >
            {header}
          </Heading>
        )}
        <Grid
          gridTemplateColumns={{
            base: '1',
            sm: 'repeat(2, minmax(280px, 1fr))',
            md: 'repeat(3, minmax(280px, 1fr))',
          }}
          {...gridProps}
          zIndex={1}
        >
          {items.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { TextDecorationPng, TextDecorationSvg } from '@/components/functional';
import {
  Enum_Componentcommoncomponentsdecoratedtextimage_Circle,
  Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim,
  Enum_Componentcommoncomponentsdecoratedtextimage_Highlight,
  Enum_Componentcommoncomponentsdecoratedtextimage_Underline,
} from '@/graphql/generated';
import { REGEX } from '@/utils/constants';
import { TextDecorationIcons, circleIcon, exclaimIcon, highlightIcon, underlineIcon } from '@/utils/text-decorations';

type DynamicTextWithDecorationProps = {
  children: string;
  isDarkMode?: boolean;
  isCircleDarkMode?: boolean;
  highlightTextColor?: string;
} & TextDecorationIcons;

export const DynamicTextWithDecoration: FC<DynamicTextWithDecorationProps> = ({
  children,
  isDarkMode,
  isCircleDarkMode,
  highlightTextColor,
  highlight = isDarkMode
    ? Enum_Componentcommoncomponentsdecoratedtextimage_Highlight.BrushedLime
    : Enum_Componentcommoncomponentsdecoratedtextimage_Highlight.SlightlyRaggedViolet,
  underline = Enum_Componentcommoncomponentsdecoratedtextimage_Underline.ThreeLinesCrossed,
  exclaim = Enum_Componentcommoncomponentsdecoratedtextimage_Exclaim.RightSunlight,
  circle = Enum_Componentcommoncomponentsdecoratedtextimage_Circle.Circle_4,
}) => {
  const parseHighlightText = (inputText: string) => {
    return inputText.split(REGEX.HIGHLIGHT_TEXT).map((part, index) => {
      if (index % 2 === 1) {
        return (
          <>
            <TextDecorationPng
              image={highlightIcon[highlight]?.icon}
              scaleX={highlightIcon[highlight]?.props?.scaleX ?? 1.1}
              key={index}
              textColor={highlightTextColor ?? 'inherit'}
            >
              {part}
            </TextDecorationPng>{' '}
          </>
        );
      } else {
        return part;
      }
    });
  };

  const parseUnderlineText = (inputText: string) => {
    return inputText.split(REGEX.UNDERLINE_TEXT).map((part, index) => {
      if (index % 2 === 1) {
        return (
          <>
            <TextDecorationSvg
              variant="line"
              color={isDarkMode ? 'lime' : 'violet'}
              line={underlineIcon[underline].icon}
              top="60%"
              zIndex={-1}
              key={index}
              {...underlineIcon[underline].props}
            >
              {part}
            </TextDecorationSvg>
          </>
        );
      } else {
        return part;
      }
    });
  };

  const parseCircledText = (inputText: string) => {
    return inputText.split(REGEX.CIRCLED_TEXT).map((part, index) => {
      if (index % 2 === 1) {
        return (
          <Box as="span" key={index}>
            <TextDecorationSvg
              variant="circle"
              color={isDarkMode || isCircleDarkMode ? 'lime' : 'violet'}
              line={circleIcon[circle].icon}
              {...circleIcon[circle].props}
            >
              {part}
            </TextDecorationSvg>
          </Box>
        );
      } else {
        return part;
      }
    });
  };

  const parseExclaimedText = (inputText: string) => {
    return inputText.split(REGEX.EXCLAIMED_TEXT).map((part, index) => {
      if (index % 2 === 1) {
        return (
          <Box as="span" key={index}>
            <TextDecorationSvg
              variant="exclaim"
              color={isDarkMode ? 'lime' : 'violet'}
              line={exclaimIcon[exclaim].icon}
              {...exclaimIcon[exclaim].props}
            >
              {part}
            </TextDecorationSvg>
          </Box>
        );
      } else {
        return part;
      }
    });
  };

  const singleWorldsToParse = children.split(/(\*\*.*?\*\*|\#\#.*?\#\#|!!.*?!!|\[.*?\]|[^ \[\#\*\!]+)/g);

  return (
    <>
      {singleWorldsToParse.map((textFragment) => {
        if (textFragment.match(REGEX.UNDERLINE_TEXT)) {
          return parseUnderlineText(textFragment);
        } else if (textFragment.match(REGEX.CIRCLED_TEXT)) {
          return parseCircledText(textFragment);
        } else if (textFragment.match(REGEX.HIGHLIGHT_TEXT)) {
          return parseHighlightText(textFragment);
        } else if (textFragment.match(REGEX.EXCLAIMED_TEXT)) {
          return parseExclaimedText(textFragment);
        } else {
          return `${textFragment}`;
        }
      })}
    </>
  );
};

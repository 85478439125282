import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import Image from 'next/image';

import { StrapiMediaAttributes } from '@/typings/strapi';
import { getImageSrcFromAttributes } from '@/utils/images';
import { getImageAlt, getImageSrcWithBlurhash } from '@/utils/images';

type VideoWrapperWithFallbackProps = {
  width?: number;
  height?: number;
  coverImageFallback: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
  coverVideo?: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
} & BoxProps;

export const VideoWrapperWithFallback = ({
  coverVideo,
  coverImageFallback,
  width = 400,
  height = 300,
  ...styles
}: VideoWrapperWithFallbackProps) => {
  return (
    <Box {...styles}>
      {coverVideo ? (
        <video
          src={getImageSrcFromAttributes(coverVideo)}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          poster={getImageSrcFromAttributes(coverImageFallback, 'medium')}
        />
      ) : (
        <Image
          {...getImageSrcWithBlurhash(coverImageFallback, 'large')}
          alt={getImageAlt(coverImageFallback)}
          width={width}
          height={height}
          style={{
            objectFit: 'contain',
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </Box>
  );
};

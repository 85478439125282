import React, { ReactNode, useRef, useState } from 'react';

import { Box, Flex, Heading, Show, useMediaQuery, useQuery } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash.debounce';
import Image from 'next/image';

import { GifSectionMenuItem, GifSectionMenuItemProps } from '@/components/sections/services/sections';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { StrapiMediaAttributes } from '@/typings/strapi';

export type GifSectionProps = {
  header: ReactNode;
  items: GifSectionItem[];
};

export type GifSectionItem = GifSectionMenuItemProps & {
  uuid: number;
  coverImageFallback: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
  coverVideo: {
    alternativeText?: string | null;
  } & StrapiMediaAttributes;
};

export const GifSection = ({ header, items }: GifSectionProps) => {
  const [selectedItem, setSelectedItem] = useState<GifSectionItem>(items[0]);
  // TODO: replace when we decide on the way to deal with media queries, ideally - add device type detection
  const [isLargerThanSm] = useMediaQuery(useQuery({ above: 'sm' }));
  const debouncedOnHover = useRef(debounce((item) => setSelectedItem(item), 60));

  return (
    <SectionWrapper>
      <SectionContentWrapper display="flex" justifyContent="center">
        <Flex w="100%" maxW="1116px" gap={12} my={6}>
          <Flex flexDir="column" flex={3}>
            <Heading
              variant={{
                base: 'component-heading-large/xl',
                sm: 'component-heading-large/2xl',
                md: 'component-heading-large/3xl',
              }}
              color="purple.500"
              mb={14}
            >
              {header}
            </Heading>
            <Flex
              flexDir="column"
              gap={{
                base: 7,
                md: 8,
              }}
            >
              {items.map((item) => (
                <GifSectionMenuItem
                  key={`gif-section-menu-item-${item.uuid}`}
                  {...item}
                  cursor={isLargerThanSm ? 'pointer' : 'initial'}
                  active={isLargerThanSm && selectedItem.text === item.text}
                  onClick={() => isLargerThanSm && setSelectedItem(item)}
                  onMouseEnter={() => (isLargerThanSm ? debouncedOnHover.current(item) : null)}
                />
              ))}
            </Flex>
          </Flex>
          <Show above="sm">
            <Flex flex={2} justifyContent="flex-end" alignItems="center">
              <Box
                h={{
                  base: '298px',
                  md: '416px',
                }}
                w={{
                  base: '246px',
                  md: '344px',
                }}
                position="relative"
              >
                <AnimatePresence>
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    transition={{ duration: 0.5 }}
                    key={`animated-gif-${selectedItem.uuid}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    }}
                  >
                    <Image
                      src={selectedItem.coverImageFallback.url || ''}
                      alt="team member"
                      fill
                      style={{ objectFit: 'cover', objectPosition: 'top center', borderRadius: '38px' }}
                    />
                    {/*<VideoWrapperWithFallback*/}
                    {/*  coverImageFallback={selectedItem.coverImageFallback}*/}
                    {/*  coverVideo={selectedItem.coverVideo}*/}
                    {/*  h="100%"*/}
                    {/*  sx={{*/}
                    {/*    '& video': {*/}
                    {/*      borderRadius: '20px',*/}
                    {/*    },*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </motion.div>
                </AnimatePresence>
                <Box
                  h="104%"
                  w="104%"
                  backgroundColor="lime.500"
                  borderRadius="20px"
                  position="absolute"
                  left="-4%"
                  top="-2%"
                  zIndex={-1}
                  transform="rotate(-6deg)"
                />
              </Box>
            </Flex>
          </Show>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

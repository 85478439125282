import React from 'react';

import { Box, Flex, Show, Text } from '@chakra-ui/react';

import { TimelineImage } from '@/components/sections/services/sections';
import { TimelineSectionItem as TimelineSectionItemType } from '@/components/sections/services/typings';

type TimelineSectionItemProps = { isLast: boolean; index: number } & TimelineSectionItemType;

export const TimelineSectionItem = ({ number, icon, header, description, isLast, index }: TimelineSectionItemProps) => {
  return (
    <Flex
      flexDir="column"
      flex={1}
      maxW={{
        base: '330px',
        sm: '380px',
        md: 'unset',
      }}
    >
      <Text variant="text-md/lineHeight-6/font-medium" color="lime.500" mb={1}>
        {number}
      </Text>
      <Box
        h={{
          base: 14,
          md: 20,
          lg: 24,
        }}
      >
        <Text
          variant={{
            base: 'text-xl/lineHeight-7/font-medium',
            md: 'text-2xl/lineHeight-8/font-medium',
            lg: 'text-3xl/lineHeight-9/font-medium',
          }}
          color="white"
          mb={6}
        >
          {header}
        </Text>
      </Box>
      <Show above="md">
        <Flex mb={6} alignItems="center">
          <TimelineImage icon={icon} className={`timeline-img-${index}`} />
          {!isLast && (
            <Box
              mr={{
                base: -10,
                md: -12,
                lg: -16,
              }}
              w="120%"
              h="5px"
              backgroundImage="radial-gradient(white 1px, transparent 26%)"
              backgroundSize="10px 6px"
            />
          )}
        </Flex>
      </Show>
      <Text variant="text-md/lineHeight-6/font-normal" color="white">
        {description}
      </Text>
    </Flex>
  );
};

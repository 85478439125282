import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import Twirl from 'images/backgrounds/twirl.png';
import Image from 'next/image';

type OShapedBackgroundProps = BoxProps;

export const OShapedBackground = ({ ...style }: OShapedBackgroundProps) => {
  return (
    <Box
      as={Image}
      src={Twirl}
      alt=""
      maxW="unset"
      right={{
        base: 'unset',
        md: '-25%',
      }}
      left={{
        base: '-200%',
        xs: '-120%',
        sm: '-40%',
        md: 'unset',
      }}
      top={{
        sm: '5%',
        md: '-10%',
      }}
      width="auto"
      height="1003px"
      zIndex={0}
      position="absolute"
      transformOrigin="100% 50%"
      transform={{
        base: 'scale(0.7) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
        sm: 'scale(0.9) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
        '2xl': 'scale(1.1) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
        '3xl': 'scale(1.1) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
      }}
      opacity={0.3}
      {...style}
    />
  );
};

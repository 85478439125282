import React, { ReactNode } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Show,
  Text,
} from '@chakra-ui/react';

import { Button } from '@/components/atoms';
import { PlusMinusIconAnimated } from '@/components/atoms/Icons/plus';
import { Technology } from '@/components/atoms/Technology';
import { TechnologyHeader } from '@/components/atoms/TechnologyHeader/TechnologyHeader';
import { technologyItems } from '@/components/atoms/TechnologyHeader/copy';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

export type DevelopmentTechnologyProps = {
  header: ReactNode;
  subheader: ReactNode;
};

export const DevelopmentTechnologySection = ({ header, subheader }: DevelopmentTechnologyProps) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <Flex
          alignItems={{
            base: 'start',
            lg: 'center',
          }}
          gap={{
            base: 6,
            lg: 20,
          }}
          mb={{
            base: 10,
            lg: 20,
          }}
          flexDir={{
            base: 'column',
            lg: 'row',
          }}
          maxW={{
            base: '100%',
            sm: '80%',
            lg: '100%',
          }}
        >
          <Box flex={1}>
            <Heading
              variant={{
                base: 'component-heading-large/lg',
                sm: 'component-heading-large/2xl',
              }}
              color="purple.500"
            >
              {header}
            </Heading>
          </Box>
          <Box flex={1}>
            <Text
              variant={{
                base: 'text-md/lineHeight-6/font-normal',
                sm: 'text-xl/lineHeight-7/font-normal',
              }}
              color="purple.500"
            >
              {subheader}
            </Text>
          </Box>
        </Flex>

        <Show above="md">
          <Grid gridTemplateColumns="repeat(4, 1fr)" gridColumnGap={6} gridRowGap={20}>
            {technologyItems.map((item) => (
              <GridItem
                gridColumn={item.gridColumn}
                display="flex"
                flexDir="column"
                alignItems="center"
                key={'technology-' + item.header}
              >
                {item.header === 'backend' ? (
                  <>
                    <TechnologyHeader mb={6}>{item.header}</TechnologyHeader>
                    <Grid gridTemplateRows="repeat(4, 1fr)" gap={6}>
                      {item.items.map((technology) => (
                        <GridItem gridRow={technology.gridRow} key={technology.name}>
                          <AnimateWhenInViewWrapper>
                            <Technology
                              technologyName={technology.name}
                              technologyImage={technology.icon}
                              w={{
                                base: '196px',
                                lg: '233px',
                              }}
                            />
                          </AnimateWhenInViewWrapper>
                        </GridItem>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <TechnologyHeader mb={6}>{item.header}</TechnologyHeader>
                    <Flex flexWrap="wrap" gap={6} justifyContent="center">
                      {item.items.map((technology) => (
                        <AnimateWhenInViewWrapper key={technology.name}>
                          <Technology
                            technologyName={technology.name}
                            technologyImage={technology.icon}
                            w={{
                              base: '196px',
                              lg: '233px',
                            }}
                          />
                        </AnimateWhenInViewWrapper>
                      ))}
                    </Flex>
                  </>
                )}
              </GridItem>
            ))}
          </Grid>
        </Show>

        <Show below="md">
          <Accordion width="100%" allowMultiple={true} defaultIndex={[0]} allowToggle={true}>
            {technologyItems.map((item, index) => (
              <AccordionItem key={`accordion-item-${index}`}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton as="div">
                      <TechnologyHeader>{item.header}</TechnologyHeader>
                      <Button
                        label=""
                        aria-label="toggle accordion item"
                        leftIcon={<PlusMinusIconAnimated plus={!isExpanded} />}
                        variant="sliderLight"
                        iconSpacing={0}
                        minH="48px"
                        minW="48px"
                        ml={2}
                      />
                    </AccordionButton>
                    <AccordionPanel>
                      <>
                        <Flex
                          flexWrap="wrap"
                          gap={{
                            base: 4,
                            sm: 6,
                          }}
                        >
                          {item.items.map((technology) => (
                            <Technology
                              technologyName={technology.name}
                              technologyImage={technology.icon}
                              key={technology.name}
                              w={{
                                base: '172px',
                                md: '196px',
                                lg: '233px',
                              }}
                            />
                          ))}
                        </Flex>
                      </>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Show>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React, { ReactNode } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

type LogosSectionProps = {
  header: ReactNode;
  items: StaticImageData[] | string[];
};

export const LogosSection = ({ header, items }: LogosSectionProps) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper
        paddingY={undefined}
        pt={{
          base: 4,
          md: 8,
        }}
        pb={{
          base: 6,
          sm: 8,
          md: 10,
        }}
      >
        <Flex flexDir="column">
          <Text
            color="purple.400"
            variant="text-md/lineHeight-6/font-normal"
            mb={{
              base: 6,
              md: 8,
            }}
            w="100%"
            textAlign="center"
          >
            {header}
          </Text>
          <Flex wrap="wrap" justifyContent="space-evenly" alignItems="center" gap={10}>
            {items.map((image, index) => (
              <Box h="70px" w="140px" position="relative" key={`logo-image-${index}`}>
                <Image
                  src={image}
                  style={{
                    objectFit: 'contain',
                  }}
                  alt=""
                  fill={true}
                />
              </Box>
            ))}
          </Flex>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React from 'react';

import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import BackgroundDoodle from 'services/grid-background-doodle.png';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';

type TailorMadeSectionProps = {
  header?: string;
  description?: string | null;
  buttonCaption?: string | null;
  buttonLink?: string | null;
};

export const TailorMadeSection = ({ header, description, buttonCaption, buttonLink }: TailorMadeSectionProps) => {
  return (
    <SectionWrapper backgroundColor="purple.600" zIndex={0} id="tailorMadeSection" overflow="hidden">
      <SectionContentWrapper
        py={{
          base: 10,
          sm: 8,
          md: 12,
        }}
        px={{ base: 5, md: 20 }}
        position="relative"
        h="100%"
        justifyContent={{ base: 'center', sm: 'start' }}
        alignItems={{ base: 'start', sm: 'center' }}
      >
        <Box
          as={Image}
          src={BackgroundDoodle}
          alt=""
          position="absolute"
          zIndex={-1}
          h="565px"
          maxW="unset"
          w="auto"
          transform="translateY(-50%)"
          top={{
            base: '80%',
          }}
          left={{
            base: '-300%',
            xs: '-150%',
            sm: '-130%',
            md: '-70%',
            lg: '-50%',
            '2xl': '-30%',
          }}
        />
        <VStack
          alignItems="flex-start"
          spacing={{
            base: 6,
            sm: 5,
            md: 6,
          }}
        >
          {header ? (
            <Heading
              as="h2"
              variant={{
                base: 'component-heading-large/xl',
                lg: 'component-heading-large/2xl',
              }}
              color="white"
            >
              {header}
            </Heading>
          ) : null}
          {description ? (
            <Text
              variant={{
                base: 'text-md/lineHeight-6/font-normal',
                sm: 'text-xl/lineHeight-7/font-normal',
              }}
              color="white"
            >
              {description}
            </Text>
          ) : null}
          {buttonCaption && buttonLink ? (
            <Button
              variant="dark"
              w={{
                base: '100%',
                xs: 'max-content',
              }}
              size="lg"
              url={buttonLink}
            >
              {buttonCaption}
            </Button>
          ) : null}
        </VStack>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Calendar from 'services/product-discovery/card-item/calendar.png';
import Crosshair from 'services/product-discovery/card-item/crosshair.png';
import Pen from 'services/product-discovery/card-item/pen.png';

import { Button } from '@/components/atoms';
import { SliderSectionCardItem } from '@/components/sections/services/typings';

const ICONS = [Calendar, Pen, Crosshair];

export const SliderIconsItem = ({ header, paragraphs, buttonLabel, buttonLink, image }: SliderSectionCardItem) => {
  return (
    <Flex flexDir="column">
      <Text
        variant={{
          base: 'text-xl/lineHeight-7/font-medium',
          md: 'text-2xl/lineHeight-8/font-medium',
          lg: 'text-3xl/lineHeight-9/font-medium',
        }}
        color="violet.500"
        mb={10}
      >
        {header}
      </Text>
      {paragraphs.map((paragraph, index) => {
        const Icon = image || ICONS[index % 3];

        if (!paragraph.header || !paragraph.text) {
          return null;
        }

        return (
          <Flex mb={6} key={`slider-icon-item-${index}`}>
            <Box as={Image} src={Icon} alt="" w="32px" h="32px" mr={4} />
            <Text
              variant={{
                base: 'text-sm/lineHeight-5/font-semibold',
                sm: 'text-sm/lineHeight-5/font-semibold',
                md: 'text-md/lineHeight-6/font-semibold',
              }}
              color="purple.500"
            >
              <Box as="span" display="block">
                {paragraph.header}
              </Box>
              <Box as="span" fontWeight={400}>
                {paragraph.text}
              </Box>
            </Text>
          </Flex>
        );
      })}
      {buttonLabel && buttonLink && (
        <Flex justifyContent="center">
          <Button mt={4} url={buttonLink} variant={'light'} maxW="100%" justifySelf="flex-end">
            <Text as="span" maxW="100%" overflow="hidden" textOverflow="ellipsis">
              {buttonLabel}
            </Text>
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
